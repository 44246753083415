import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/subscription', title: 'Subscription', icon: 'ft-settings', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/subscription/function', title: 'Function', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/subscription/feature', title: 'Feature', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/subscription/module', title: 'Module', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      // {
        //   path: '/subscription/feature-function', title: 'Feature Function', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        // },
        {
          path: '/subscription/subscription-type', title: 'Subscription Plan Type', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/subscription/subscription', title: 'Subscription', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/subscription/company', title: 'Company', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '/api-management', title: 'API Management', icon: 'ft-cpu', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/api-management/google-api', title: 'Places API', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
];

<section>
  <div #fullScreen [ngClass]="isFullScreen ? 'custom-bg px-2':''">
    <header-title [headerTitle]="headerTitle">
      <div class="d-flex justify-content-end mb-2">
        <input *ngIf="isEnableMultiSearch" [(ngModel)]="searchInput" placeholder={{placeholderText}} type="text"
          class="form-control w-75 h-75">
        <button type="button" class="btn bg-transparent" style="margin-left: -40px; z-index: 100;"
          (click)="clearSearch()" *ngIf="searchInput">
          <i class="fa fa-times"></i>
        </button>
        <button *ngIf="isEnableMultiSearch" [disabled] = "!searchInput" class="btn btn-sm btn-success ml-1" (click)="multiSearch()">Search</button>

        <button *ngIf="addBtn" type="button" class="btn btn-sm btn-success ml-1" data-toggle="#exampleModal"
          (click)="addNewFunc()" data-target="#exampleModal">
          <i class="ft-plus"></i>
          Add {{ addButtonTitle}}
        </button>
      </div>
    </header-title>
    <div>
      <div class="row border-top border-bottom py-2" [ngStyle]="{'min-height': !showCompany ? '5rem' : '0rem'}">
        <div class="col-7 m-h">
          <div class="row ml-0" *ngIf="isShowDateFilters">
            <div class="ml-1 mt-1 custom-switch custom-switch-success d-inline-block mb-1 mb-xl-0">
              <input type="checkbox" id="color-switch-3" (change)="onDateToggle()" [(ngModel)]="isDateTogggleEnable"
                class="custom-control-input">
              <label for="color-switch-3" class="custom-control-label mr-2">
              </label>
            </div>
            <div class="form-group mr-1">
              <div class="input-group">
                <!-- <input class="form-control" placeholder="From" [formControl]="startDateControl" [matDatepicker]="picker"
                  [appDisableControl]="!isDateTogggleEnable"> -->
                <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                  [formControl]="startDateControl">
                <!-- <mat-datepicker #picker></mat-datepicker> -->
                <div class="input-group-append" (click)="dp.toggle()">
                  <div class="input-group-text">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mr-1">
              <div class="input-group">
                <input type="text" class="form-control" #dp1="bsDatepicker" bsDatepicker [formControl]="endDateControl">
                <div class="input-group-append" (click)="dp1.toggle()">
                  <div class="input-group-text">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                  </div>
                </div>
              </div>
            </div>


            <div class="d-flex">
              <select placeholder="Report Date" class="form-control" [(ngModel)]="reportDateTime"
                [appDisableControl]="!isDateTogggleEnable">
                <option value="all">Report Date</option>
              </select>
            </div>
          </div>
          <!-- <div class="row" *ngIf="!showCompany">
            <div class="col-8">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Company</mat-label>
                <mat-select matNativeControl place placeholder="Select Company" [(value)]="selectedCompany" disabled>
                  <mat-option *ngFor="let company of CompanyArr" [value]="company">
                    {{company.companyName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
        </div>
        <div class="col-5">
          <div class="d-flex justify-content-end">
            <div *ngIf="isSearch" class="mr-1 flex-fill">
              <select class="form-control form-control-sm" [(ngModel)]="selectedOptionValue" (change)="onSelectOption()">
                <option [value]="'Assets'">Tracked Assets</option>
                <option [value]="'Devices'">Un-Installed Devices</option>
                <option [value]="'All'">Show All </option>
              </select>
            </div>
            
            <!-- <button class="btn btn-sm btn-success mr-1" (click)="openImportModal(importTemplate)" *ngIf="importURL">
              <i class="ft-upload"></i>
              Import
            </button> -->
            <button class="btn btn-sm btn-success mr-1" (click)="showHideFilter()">
              <i class="ft-filter"></i>
              Filter
            </button>
            <button type="button" class="btn btn-sm btn-success mr-1" placement="bottom" (click)="openModal(menu)">
              <i class="ft-list"></i>
              Columns
            </button>
            <button class="btn btn-sm btn-warning mr-1" (click)="ResetToDefault()">
              <i class="ft-refresh-cw"></i>
            </button>
            <!-- <button class="btn btn-sm btn-outline-success" (click)="fullScreenTable(fullScreen)">
              <i [ngClass]="isFullScreen ? 'font-medium-3 ft-minimize':'font-medium-3 ft-maximize'"></i>
            </button> -->
          </div>
        </div>
      </div>

      <div class="card w-100">
        <div class="card-content">
          <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
          <perfect-scrollbar [scrollIndicators]="true">
            <!-- [resizeColumn]="true" -->
            <div class="custom-table">
              <table mat-table matSort (matSortChange)="announceSortChange($event)" [dataSource]="dataSource"
                class="mitable e-table table-striped table-bordered border mat-table  example-boundary" readonly>

                <ng-container *ngFor="let column of columns ; let i=index" [matColumnDef]="column.field">
                  <th *matHeaderCellDef mat-header-cell cdkDragBoundary=".example-boundary" [index]="i"
                    class="mat-row text-capitalize resizeColumn" [style.width]="'115px'">

                    <!-- changed width from 115px to 100% -->
                    <!-- class="mat-row text-capitalize resizeColumn" [style.width]="'115'" -->
                    
                    <!-- <div class="example-custom-placeholder" *cdkDragPlaceholder></div> -->
                    <!-- <div class="example-handle float-left mr-1">
                      <i class="ft-move"></i>
                    </div> -->
                    <strong mat-sort-header [disabled]="!column.isShowSort" class="text-nowrap"
                      [title]="column.title || column.label">
                      <!-- [title]="column.title || column.label || column.field"> -->
                      {{ column.label || column.field }}
                    </strong>
                  </th>
                  <ng-container *ngIf="column.cellTemplate">
                    <td #columnCells class=" truncate mat-cell" mat-cell
                      *matCellDef="let row; let i=index; let column1=column;"
                      [title]="showTooltipCondition() ? showTooltipValue(row, column) :''">
                      <ng-container [ngTemplateOutlet]="column.cellTemplate"
                        [ngTemplateOutletContext]="{$implicit: column1, rowData: row, columnValue: column1}">
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngIf="!column.cellTemplate">
                    <!-- row[column.field] -->
                    <td #columnCells class=" truncate mat-cell" mat-cell *matCellDef="let row"
                      [title]="showTooltipCondition() ? row[column.field] != null?row[column.field]:'' : ''">
                      <ng-container *ngIf="!row[column.field]">
                        {{ row[column.field] | showValue}}
                      </ng-container>
                      <ng-container *ngIf="row[column.field]">
                        {{ column.prefix || '' }}{{ row[column.field] }}{{ column.postfix || '' }}
                      </ng-container>
                    </td>
                  </ng-container>

                </ng-container>

                <ng-container *ngFor="let column of columns; trackBy: trackByFn;
              let i = index" [matColumnDef]="column.field+'-filter'">
                  <th *matHeaderCellDef mat-header-cell>
                    <ng-container *ngIf="column.isShowFilter && isShowFilterRow">
                      <ngx-column-filter [column]="column" (changed)="applyFilter($event)"></ngx-column-filter>
                    </ng-container> 
                  </th>
                </ng-container>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center" [attr.colspan]="100">
                    <i class="font-medium-3 ft-info d-block m-2"></i>
                    <strong>{{ isLoading ? "Data is loading." : "No data Found." }}</strong>
                  </td>
                </tr>
                <!-- sticky: true -->
                <tr class="mat-row" mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr class="mat-row" mat-header-row *matHeaderRowDef="filterColumns;sticky: true"
                  [hidden]="!isShowFilterRow">
                </tr>
                <tr class="mat-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table>
            </div>
          </perfect-scrollbar>
          <div class="py-0 card-footer">
            <div class="row">
              <!-- Changed column value -->
              <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 p-0"> -->
              <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-3 p-0">
                <!-- <button [disabled]="dataSource?.data?.length == 0" class="btn btn-sm btn-success mt-2"
                  (click)="export()" *ngIf="isShowExport">
                  <i class="ft-download"></i>
                  Export to Excel
                </button> -->
              </div>
              <div class="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 text-center mt-2 last-refresh-m p-0">
                <div class="float-right">
                  <i class="fa fa-redo c-pointer text-success " (click)="refresh()"></i>
                  {{ timeDelay != "" ? timeDelay : 'Auto Refresh (5 min cycle)' }}
                  <time datetime="1681465896066">{{ lastRefresh | date:'mediumTime' }}</time>
                  <div class="ml-1 custom-switch custom-switch-success d-inline-block mb-1 mb-xl-0">
                    <input type="checkbox" id="color-switch-4" (change)="onChangeLastRefresh()" [(ngModel)]="isLastRef"
                      class="custom-control-input">
                    <label for="color-switch-4" class="custom-control-label mr-1">
                      <!-- <span>Success</span> -->
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0">
                <mat-paginator class="float-right" *ngIf="dataSource && dataSource.data.length" #paginator
                  [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" [showFirstLastButtons]="true">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #menu>
  <column-manager [tableSettingKey]="tableSettingKey" [menuColumns]="menuColumns" (changed)="applyColumnChanged($event)"
    (reload)="onReload()" (closeModalEvent)="closeModal()">
  </column-manager>
</ng-template>


<ng-template #importTemplate>
  <div class="child-modal">
    <div class="modal-header">
      <header-title class="w-100" [headerTitle]="'Import Data'">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeImportModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </header-title>
    </div>
    <perfect-scrollbar
      [config]="{ useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false, wheelPropagation: true }">
      <div class="modal-body modal_height">
        <ng-container *ngTemplateOutlet="importInstructions"></ng-container>
        <hr>

        <ul class="col" *ngIf="importValidationsErrors.length">
          <li class="danger list-group-item ng-star-inserted w-75" *ngFor="let error of importValidationsErrors">
            {{error}}</li>
        </ul>

        <div class="center">
          <div class="row" *ngIf="uploadedDocuments.length > 0">
            <div class="col-12 mb-3" style="display: -webkit-box;" *ngFor="let item of uploadedDocuments;let i = index">
              <strong>{{item.fileName}}</strong>
              <i class="ft-trash-2 danger cursor-pointer ml-5" (click)="deleteDoc(i)">
              </i>
            </div>
          </div>

          <ngx-file-drop accept=".csv" dropZoneLabel="Drop files here" (onFileDrop)="onFileSelected($event)"
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <strong>Drag 'n' drop some files here, or click to select files</strong>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <span>or</span>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-sm btn-success" (click)="openFileSelector()">Browse
                    Files</button>
                </div>
              </div>
            </ng-template>
          </ngx-file-drop>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary text-right" data-dismiss="modal" (click)="closeImportModal()">
        <i class="ft-x"></i> Close
      </button>
    </div>
  </div>
</ng-template>
import Swal, { SweetAlertOptions } from 'sweetalert2';

export function Confirmable(options?: SweetAlertOptions): MethodDecorator {
  return function (target: any, key: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    const config: SweetAlertOptions = {
      title: 'Are you sure?',
      text: 'Do you want to perform this action?',
      showDenyButton: true,
      confirmButtonColor: '#40c057',
      confirmButtonText: 'Yes, do it!',
      denyButtonText: 'No, cancel!',
      icon: 'question'
    };
    if (options) {
      Object.assign(config, options);
    }

    const updatedDescriptor: PropertyDescriptor = {
      ...descriptor,
      value: async function (...args: any[]) {
        const result = await Swal.fire(config);
        if (result.isConfirmed) {
          const originalResult = originalMethod.apply(this, args);
          return originalResult;
        }
      }
    };

    Object.defineProperty(target, key, updatedDescriptor);
    return updatedDescriptor;
  };
}
